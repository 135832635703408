<h3>{{
    this.expansionRoutesViewService.isEditingRoute ?
        ls.get('EDIT_ROUTE', 'app') :
        ls.get('CREATE_NEW_ROUTE', 'app')
    }}
</h3>

<mat-form-field>
    <mat-label>{{ls.get('NAME', 'personal-data')}}</mat-label>
    <input id="routeNameInput" matInput autoFocusOnDisplay autoSelectTextOnFocus
        [(ngModel)]="expansionRoutesViewService.routeBeingCurrentlyEdited.name">
</mat-form-field>

<mat-form-field>
    
    <mat-label>{{ls.get('CUSTOMERS', 'shopping')}}</mat-label>
    
    <textarea id="routeCustomersInput" matInput autoSelectTextOnFocus
        [(ngModel)]="expansionRoutesViewService.routeBeingCurrentlyEdited.customers">
    </textarea>
       
</mat-form-field>

<div class="submitButtonsContainer">
    
   <button mat-button
        id="cancelRouteButton"
        (click)="closeDialog(0)">
        
        {{this.ls.get('CANCEL', 'user-interface')}}
        
    </button>
    
     <button mat-raised-button color="primary"
        id="saveRouteButton"
        [disabled]="isSaveButtonDisabled()"
        (click)="saveRoute()">
        
        {{this.ls.get('SAVE', 'user-interface')}}
        
    </button>
    
</div>