<h3>{{
    this.expansionRoutesViewService.isEditingExpedition ?
        ls.get('EDIT_EXPEDITION', 'app') :
        ls.get('CREATE_NEW_EXPEDITION', 'app')
    }}
</h3>

<mat-form-field>
    <mat-label>{{ls.get('NAME', 'personal-data')}}</mat-label>
    <input id="expeditionNameInput" matInput autoFocusOnDisplay autoSelectTextOnFocus
        [(ngModel)]="expansionRoutesViewService.expeditionBeingCurrentlyEdited.name">
</mat-form-field>

<div class="scrollContainer">

     <div class="headerContainer">
                
        <h4>{{ls.get('ORDER_NUMBER', 'shopping')}}</h4>
     
        <!-- Dummy checkbox to keep columns aligned, not used -->
        <mat-checkbox></mat-checkbox>
        
        <h4>{{ls.get('ORDER_DATE', 'shopping')}}</h4>
        <h4>{{ls.get('DELIVERY_DATE', 'app')}}</h4>
        <h4>{{ls.get('ESTIMATE_DATE', 'app')}}</h4>
    </div>

    <div class="tableContainer"
        *ngFor="let customerData of expansionRoutesViewService.routeDetails | keyvalue">
            
        <h5>{{ls.get('CUSTOMER', 'shopping') + ': ' + customerData.key}}</h5>
        
        <div *ngFor="let item of customerData.value.orders; let i = index"
            id="expeditionListItem_{{i}}">
            
            <mat-checkbox [(ngModel)]="selectionOrders[item[0]]">                            
            </mat-checkbox>
            
            <p>{{item[0]}}</p>
            <p>{{item[1]}}</p>
            <p>{{item[2]}}</p>
            <p>{{item[3]}}</p>
                    
        </div>
        
    </div>
    
</div>

<div class="submitButtonsContainer">
    
   <button mat-button
        id="cancelExpeditionButton"
        (click)="closeDialog(0)">
        
        {{this.ls.get('CANCEL', 'user-interface')}}
        
    </button>
    
     <button mat-raised-button color="primary"
        id="saveExpeditionButton"
        [disabled]="isSaveButtonDisabled()"
        (click)="saveExpedition()">
        
        {{this.ls.get('SAVE', 'user-interface')}}
        
    </button>
    
</div>