import { Injectable } from '@angular/core';
import { ViewsService, TurboApiCallerService, LocalizationService, DialogService, ViewService } from 'turbogui-angular';
import { OrderHistoryDetailsComponent } from './order-history-details.component';


/**
 * The service for the expansion view
 */
@Injectable()
export class ExpansionRoutesViewService extends ViewService{


    /**
     * Flag that tells if we are showing the details of a route
     */    
    isShowingRouteDetails = false;
    
    
    /**
     * List of routes that are available
     */
    routesList: any[] = [];


    /**
     * Flag that tells if we are modifying a route
     */    
    isEditingRoute = false;
    
    
    /**
     * Data for the route that is currently being edited
     */
    routeBeingCurrentlyEdited:any = null;
    
    
    /**
     * Data for the currently selected route
     */
    routeDetails = [];


    /**
     * Date to filter the list of route details by estimate date
     */
    routeDetailsDateFilter = '';
    
    
    /**
     * Id for the expedition that is defined to filter the route details
     */
    routeDetailsExpeditionFilter:any = null;
    
    
    /**
     * Data for the order that is being currently shown
     */
    currentOrderData:any = null;
    
    
    /**
     * ID for the order that is being currently shown
     */
    currentOrderNumber = '';
    
    
    /**
     * Comments for the order that is being currently shown
     */
    currentOrderComments = '';
    
    
    /**
     * List of expeditions that are available for the current route
     */
    expeditionsList: any[] = [];
    
    
    /**
     * Flag that tells if we are modifying an expedition
     */    
    isEditingExpedition = false;
    
    
    /**
     * Data for the expedition that is currently being edited
     */
    expeditionBeingCurrentlyEdited:any = null;
    
    
    /**
     * The code for the customer when order line data is being shown
     */
    currentCustomerCode = '';
    
    
    /**
     * The name for the customer when order line data is being shown
     */
    currentCustomerName = '';
        

    constructor(public viewsService: ViewsService,
                public ls:LocalizationService,
                public dialogService: DialogService,
                public apiService: TurboApiCallerService) {
                    
        super();
    }
    
    
    loadRoutes(){
        
        this.routesList = [];
        this.routeDetails = [];
                
        this.apiService.call('expansion-routes/routes-list')
            .then(response => {
            
            this.routesList = response;
        });
    }
    
    
    loadRouteOrders(){
        
        this.apiService.call('expansion-routes/route-get-orders',
            {routeId: this.routeBeingCurrentlyEdited.dbId,
             estimateDateFilter: this.routeDetailsDateFilter,
             expeditionFilter: this.routeDetailsExpeditionFilter === null? '' : this.routeDetailsExpeditionFilter.dbId })
            .then(response => {
                    
                this.routeDetails = response;         
            });
    }
    
    
    loadExpeditions(){
        
        this.expeditionsList = [];
                
        this.apiService.call('expansion-routes/expeditions-list',
            {routeId: this.routeBeingCurrentlyEdited.dbId})
            .then(response => {
            
            this.expeditionsList = response;
        });
    }
    
    
    /**
     * Load the details of an order as a popup 
     */
    loadOrderData(orderNumber:string, customerCode:string, customerName:string, orderComments:string) {

        this.currentOrderData = null;
        this.currentOrderNumber = orderNumber;
        this.currentOrderComments = orderComments;
        this.currentCustomerCode = customerCode;
        this.currentCustomerName = customerName;
        
        this.apiService.call('orders/get-navision-order-details',
            {orderNumber: orderNumber, "codigo-cliente": this.currentCustomerCode})
            .then(response => {
                    
            this.currentOrderData = response;
                        
            this.dialogService.addDialog(OrderHistoryDetailsComponent, {
                width: '1500px',
                modal: false,
                viewContainerRef: this.viewContainerRef});
        });
    }
}
