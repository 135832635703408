<div class="mainContainer">

    <h3>{{this.ls.get('CUSTOMER', 'shopping') + ': ' + 
        expansionRoutesViewService.currentCustomerCode + ' - ' + expansionRoutesViewService.currentCustomerName + ' - ' + expansionRoutesViewService.currentOrderNumber }}
    </h3>
    
    <div class="lineContainer">
        <h4>{{this.ls.get('REFERENCE', 'shopping')}}</h4>
        <h4>{{this.ls.get('VARIANT', 'app')}}</h4>
        <h4>{{this.ls.get('AMOUNT', 'shopping')}}</h4>
        <h4>{{this.ls.get('PENDING_AMOUNT', 'app')}}</h4>
        <h4>{{this.ls.get('SHIPPED_AMOUNT', 'app')}}</h4>
        <h4>{{this.ls.get('PENDING_TO_RECEIVE', 'app')}}</h4>
        <h4>{{this.ls.get('DELIVERY_DATE', 'app')}}</h4>
        <h4>{{this.ls.get('ESTIMATE_DATE', 'app')}}</h4>
        <h4>{{this.ls.get('ORDER_DATE', 'shopping')}}</h4>
    </div>
    
    <div class="lineContainer"
        [class.hasPendingToReceive]="isGreaterThanZero(line[8])"
        *ngFor="let line of expansionRoutesViewService.currentOrderData">
        
        <p>{{line[0]}}</p>
        <p>{{line[5]}}</p>
        <p>{{line[1]}}</p>
        <p>{{line[2]}}</p>
        <p>{{line[6]}}</p>
        <p>{{line[8]}}</p>
        <p>{{line[3]}}</p>
        <p>{{line[7]}}</p>
        <p>{{line[4]}}</p>
    </div>
</div>

<div class="bottomContainer">
    
    <div>
        <h5 (click)="editObservations()">
            <img id="editOrderobservationsButton" src="./assets/icons/edit.svg"
                matTooltip="{{ls.get('OBSERVATIONS', 'user-interface')}}"
                (click)="closeDialog(1)"/>
                
            {{ls.get('OBSERVATIONS', 'user-interface')}}
        </h5>
        
        <p>
            {{expansionRoutesViewService.currentOrderComments}}
        </p>
    </div>
    
    <button id="confirmCustomerButton" mat-raised-button color="primary"
        (click)="closeDialog(1)">
        
        {{this.ls.get('CLOSE', 'user-interface')}}
    </button>
    
</div>