import { Component, Inject, ElementRef } from '@angular/core';
import { LocalizationService, DialogBaseComponent, ViewsService, TurboGuiAngularModule, TurboApiCallerService} from 'turbogui-angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ExpansionRoutesViewService } from './expansion-routes-view.service';
import { StringUtils } from 'turbocommons-ts';


/**
 * Edit expedition component
 */
@Component({
    selector: 'app-expedition-edit',
    standalone: true,
    imports: [CommonModule, FormsModule, MatCheckboxModule, MatInputModule, MatButtonModule, TurboGuiAngularModule],
    providers: [],
    templateUrl: './expedition-edit.component.html',
    styleUrls: ['./expedition-edit.component.scss']
})


export class ExpeditionEditComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "RouteEditComponent";


    /**
     * Contains the state of the orders selection.
     * Each object key is the identifier for an order, and the value will be true or false
     * depending on if it is selected.
     */
    selectionOrders:any = {};
    
    
    constructor(public elementRef: ElementRef, 
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalizationService,
                public viewsService: ViewsService,
                public expansionRoutesViewService: ExpansionRoutesViewService,
                public apiService: TurboApiCallerService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
                    
        super(elementRef, dialogRef);
        
        // Find all the selected orders on the expedition currently being edited and
        // fill the selection orders object with true for each one
        let orders = this.expansionRoutesViewService.expeditionBeingCurrentlyEdited.orders.split(',');
        
        for(let orderId of orders){
                
            this.selectionOrders[orderId] = true;
        }
    }
    
    
    isSaveButtonDisabled(){
        
        // Find at least one order selected
        let isAnyOrderSelected = false;
        
        for(let orderId in this.selectionOrders){
        
            if(this.selectionOrders[orderId] === true){
                
                isAnyOrderSelected = true;
                break;
            }    
        }
        
        return StringUtils.isEmpty(this.expansionRoutesViewService.expeditionBeingCurrentlyEdited.name) || !isAnyOrderSelected;
    }
    
    
    saveExpedition(){
    
        // Loop all the selected orders and add them to the expedition if checked
        let selectedOrders:string[] = [];
        
        for(let orderId in this.selectionOrders){
        
            if(this.selectionOrders[orderId] === true){
                
                selectedOrders.push(orderId);
            }    
        }
        
        this.expansionRoutesViewService.expeditionBeingCurrentlyEdited.orders = selectedOrders.join();
        
        this.apiService.call('expansion-routes/expedition-save',
            {expeditionObject: this.expansionRoutesViewService.expeditionBeingCurrentlyEdited})
            .then(response => {
            
            if(response >= 0){
            
                this.expansionRoutesViewService.loadExpeditions();
                this.closeDialog(1);
            }
        });    
    }
}
