import { Component, OnDestroy, AfterViewInit, ViewContainerRef } from '@angular/core';
import { ViewsService, View, LocalizationService, DialogService, TurboApiCallerService, ButtonContainerComponent, DialogTwoOptionComponent } from 'turbogui-angular';
import { ExpansionRoutesViewService } from './expansion-routes-view.service';
import { ContentFadeAnimationClass } from '../../../view/animations/content-fade.animation';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AppModel } from 'src/main/model/models/app.model';
import { CommonModule } from '@angular/common';
import { RouteEditComponent } from './route-edit.component';
import { ExpeditionsListComponent } from './expeditions-list.component';


/**
 * expansion view
 */
@Component({
    selector: 'expansion-routes-view',
    standalone: true,
    imports: [CommonModule, ButtonContainerComponent, MatTooltipModule],
    providers: [ExpansionRoutesViewService],
    animations: [ContentFadeAnimationClass.getTrigger()],
    templateUrl: './expansion-routes-view.component.html',
    styleUrls: ['./expansion-routes-view.component.scss']
})


export class ExpansionRoutesViewComponent extends View implements AfterViewInit, OnDestroy {


    constructor(public ls: LocalizationService,
                public viewsService: ViewsService,
                public dialogService: DialogService,
                public appModel: AppModel,
                public apiService: TurboApiCallerService,
                public expansionRoutesViewService: ExpansionRoutesViewService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, expansionRoutesViewService);

        this.appModel.mainTitle = this.ls.get('EXPANSION', 'app');
    }


    ngAfterViewInit() {

        this.expansionRoutesViewService.loadRoutes();
    }
    
    
    createNewRoute(){
        
        this.expansionRoutesViewService.isEditingRoute = false;
        this.expansionRoutesViewService.routeBeingCurrentlyEdited = {
            name: '',
            customers: ''
        };
    
        this.dialogService.addDialog(
            RouteEditComponent,
            {
                width: '800px',
                viewContainerRef: this.expansionRoutesViewService.viewContainerRef
            });
    }
    
    
    editRoute(){
    
        this.expansionRoutesViewService.isEditingRoute = true;
        
        this.dialogService.addDialog(
            RouteEditComponent,
            {
                width: '800px',
                viewContainerRef: this.expansionRoutesViewService.viewContainerRef
            });
    }
    
    
    showExpeditions(){
        
        this.expansionRoutesViewService.loadExpeditions();
                
        this.dialogService.addDialog(
            ExpeditionsListComponent,
            {
                width: '700px',
                modal: false,
                texts: [],
                viewContainerRef: this.expansionRoutesViewService.viewContainerRef
            });
    }
    
    
    selectDateFilterDate(){
        
        this.dialogService.addDateSelectionDialog({
                id: 'dateFilterDialog',
                width: '500px',
                title: this.ls.get('FILTER_BY_ESTIMATE_DATE', 'app'),
                viewContainerRef: this.viewContainerRef
            }, (selectedDate) => {
        
            if(selectedDate !== null){
                
                let year = selectedDate.getFullYear();
                let month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
                let day = selectedDate.getDate().toString().padStart(2, '0');
                 
                this.expansionRoutesViewService.routeDetailsDateFilter = `${year}/${month}/${day}`;
                this.expansionRoutesViewService.loadRouteOrders();
            }
        });
    }
    
    
    clearDateFilter(){
    
        this.expansionRoutesViewService.routeDetailsDateFilter = "";
        this.expansionRoutesViewService.loadRouteOrders(); 
    }
    
    
    clearExpeditionFilter(){
    
        this.expansionRoutesViewService.routeDetailsExpeditionFilter = null;
        this.expansionRoutesViewService.loadRouteOrders(); 
    }
    
    
    deleteRoute(){
        
        this.dialogService.addDialog(
            DialogTwoOptionComponent,
            {
                width:'500px',
                modal: false,
                texts: [this.ls.get('DELETE_ROUTE', 'app') + '?', this.ls.get('CONFIRM_DELETE_ROUTE', 'app', '', this.expansionRoutesViewService.routeBeingCurrentlyEdited.name)],
                options: [this.ls.get('DELETE_ROUTE', 'app'), this.ls.get('CANCEL', 'user-interface')]
            },
            (selection) =>{
            
                if(selection.index === 0){
                    
                    this.apiService.call('expansion-routes/route-delete',
                        {routeId: this.expansionRoutesViewService.routeBeingCurrentlyEdited.dbId})
                        .then(response => {
                                
                            if(response === true){
                                
                                this.expansionRoutesViewService.isShowingRouteDetails = false;
                                this.expansionRoutesViewService.loadRoutes();
                            }
                        });
                }
            });
    }
    
    
    showRouteDetails(item:any){
        
        this.expansionRoutesViewService.routeBeingCurrentlyEdited = item;
        this.expansionRoutesViewService.isShowingRouteDetails = true;
        this.expansionRoutesViewService.loadRouteOrders();
    }
    
    
    closeRouteDetails(){

        this.expansionRoutesViewService.loadRoutes();
        this.expansionRoutesViewService.isShowingRouteDetails = false;
    }
    
    
    ngOnDestroy() {

        this.appModel.mainTitle = '';
    }
}